import React from "react";
import aboutImg from "../images/header.jpg";

const About = () => {
  return (
    <div className="mt-12 w-[90%] mx-auto">
      <div className="about-display-wrapper">
        <div className="">
          <div className="box">
            <div className="md:h-[400px] h-[750px] flex justify-center items-center relative">
              <img
                src={aboutImg}
                alt=""
                className="mx-auto h-full w-full rounded-2xl"
              />
              <div className="absolute rounded-2xl w-full h-full top-0 bottom-0 opacity-90 bg-coco"></div>

              <div className="absolute gap-0 md:gap-20 top-10 bottom-10 left-10 right-10 flex justify-center md:flex-nowrap flex-wrap">
                <div className="md:w-[60%] py-9 w-[100%] mx-auto">
                  <h4 className="text-orange font-bold text-heading">
                    ABOUT COMPANY
                  </h4>
                  <h2 className="text-white font-bold md:text-5xl text-2xl  mt-4">
                    CONNECT WITH <span className="text-orange">INDUSTE.</span>
                  </h2>
                  <h3 className="md:text-4xl text-xl leading-relaxed mt-4 font-bold text-white">
                    WE HAVE <span className="text-oranges">28 YEARS</span> OF
                    EXPERIENCE with 100% job success.
                  </h3>
                </div>
                <div className="md:w-[40%] py-9  w-[100%]">
                  <div className="text-white">
                    <p className=" leading-relaxed">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard printer.
                    </p>
                    <p className="mt-4  leading-relaxed">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard printer.
                    </p>
                    <p className="mt-4  leading-relaxed">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
