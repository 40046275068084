import React from "react";
import group from "../images/group.png";

const Solutions = () => {
  return (
    <>
      <div className="mt-12 w-[90%] mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold ">
            Simple Cost-Efficient Mining Solutions
          </h2>
          <p className="mt-3">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been<br></br> the industry's standard dummy text
            ever since the.
          </p>
          <div className="mt-12">
            <img src={group} alt="" className="mx-auto" />
          </div>
          <button className="bg-redd py-3 px-6 underline text-white font-bold" > See ALL Serviecs</button>
        </div>
      </div>
    </>
  );
};

export default Solutions;
