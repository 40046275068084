import React from "react";
import serviceImg from "../images/service-icon-1.png (1).png";
import serviceImg1 from "../images/service-icon-2.png (1).png";
import serviceImg2 from "../images/service-icon-3.png (1).png";
import serviceImg3 from "../images/service-icon-4.png (1).png";

const Service = () => {
  return (
    <div className="mt-12 w-[90%] mx-auto ">
      <div className=" text-center py-14 px-6">
        <h4 className="text-orange font-bold text-lg">OUR SERVICES</h4>
        <h2 className="text-5xl font-bold text-coco mt-3">WHAT WE DO?</h2>

        <div className="bg-pinky p-5 mt-12">
          <div className="mt-12">
            <div className="flex justify-between gap-10 flex-wrap md:flex-nowrap">
              <div className="md:w-[50%] w-[100%]">
                <div className="flex items-center border-2 border-backg gap-10 hover:text-orange hover:bg-backg py-7 px-3 rounded-lg">
                  <div className="">
                    <img className="h[120px]" src={serviceImg} alt="" />
                  </div>
                  <div className="text-start">
                    <h4 className="text-2xl font-bold text-coco">
                      Industrial Manufacturing
                    </h4>
                    <button className="bg-white mt-4 font-bold py-2 px-4 rounded-full text-gery uppercase">
                      details
                    </button>
                  </div>
                </div>
              </div>

              <div className="md:w-[50%] w-[100%]">
                <div className="flex items-center gap-10 border-2 border-backg hover:text-orange hover:bg-backg py-7 px-3 rounded-lg">
                  <div className="">
                    <img className="h-[120px]" src={serviceImg1} alt="" />
                  </div>
                  <div className="text-start">
                    <h4 className="text-2xl font-bold text-coco">
                      Automotive Manufacturing
                    </h4>
                    <button className="bg-white mt-4 font-bold py-2 px-4 rounded-full text-gery uppercase">
                      details
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-10 flex-wrap md:flex-nowrap mt-20">
              <div className="md:w-[50%] w-[100%]">
                <div className="flex items-center gap-10 border-2 border-backg hover:text-orange hover:bg-backg py-7 px-3 rounded-lg">
                  <div className="">
                    <img className="h-[120px]" src={serviceImg2} alt="" />
                  </div>
                  <div className="text-start">
                    <h4 className="text-2xl font-bold text-coco">
                      Mechanical Engineering
                    </h4>
                    <button className="bg-white mt-4 font-bold py-2 px-4 rounded-full text-gery uppercase">
                      details
                    </button>
                  </div>
                </div>
              </div>

              <div className="md:w-[50%] w-[100%]">
                <div className="flex items-center gap-10 border-2 border-backg hover:text-orange hover:bg-backg py-7 px-3 rounded-lg">
                  <div className="">
                    <img className="h-[120px]" src={serviceImg3} alt="" />
                  </div>
                  <div className="text-start">
                    <h4 className="text-2xl font-bold text-coco">
                      Building Management
                    </h4>
                    <button className="bg-white mt-4 font-bold py-2 px-4 rounded-full text-gery uppercase">
                      details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" text-center mt-12">
            <button className="text-white hover:text-black font-bold bg-gradient-to-r from-orange to-oranges px-5 py-3 rounded-3xl">
              Othere Serviecs
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
