import React from "react";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Project from "./components/Project";
import Choice from "./components/Choice";
import Stafe from "./components/Stafe";
import Trunk from "./components/Trunk";
import Clinet from "./components/Clinet";
import Footeer from "./components/Footeer";
import { Helmet } from "react-helmet";

const Minings = () => {
  return (
    <div>
       <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Mining || mining  </title>
      </Helmet>
    <div>
      <Navbar />
      <About />
      <Project />
      <Choice />
      <Stafe />
      <Trunk />
      <Clinet />
      <Footeer />
    </div>
    </div>
  );
};

export default Minings;
