import React from "react";
import jcb from "../images/jcb.png";
import truck from "../images/truck.png";

const Trunk = () => {
  return (
    <>
      <div className=" mt-12 w-[90%] mx-auto">
        <div className="">
          <div className="flex gap-96">
            <div className="col-md-6 jkhgkj">
              <div className="truck_img1">
                <img src={truck} alt="#" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="truck_img1">
                <img src={jcb} alt="#" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trunk;
