import "./App.css";
import Cermining from "./cermining/Cermining";
import Cermomin from "./cermo_mining/Cermomin";
import Minings from "./minings/Minings";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Notfound from "./Notfound";
import MiningTemp from "./MiningTemp";

function App() {
  return (
    <>
     
      <div>
        <BrowserRouter>
          <Routes>
            <Route excat path="/" element={<MiningTemp />} />
            <Route excat path="/Mining-technology" element={<Cermining />} />
            <Route excat path="/mining" element={<Minings />} />
            <Route excat path="/mining-customer-management-solution" element={<Cermomin />} />

            <Route path="*" element={<Notfound />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
