import React, { useEffect, useState } from "react";
// import Image1 from "./IMG/Group 321.png";
// import Image2 from "./IMG/Group 322.png";
// import Image3 from "./IMG/Group 320.png";
// import Image4 from "./IMG/Group 335.png";
// import logo from './IMG/Group 280.png'
import main from "./img/main.png";
import minings from "./img/minings.png";

import mining from "./img/mining.png";

import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

import logofoter from "./img/logofoter.png";
import { HiLocationMarker } from "react-icons/hi";
import { RiWhatsappLine } from "react-icons/ri";
import { CiFacebook } from "react-icons/ci";

import {
  AiOutlineMail,
  AiFillTwitterSquare,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
import { BsTelephoneOutboundFill } from "react-icons/bs";
import { FaInstagramSquare } from "react-icons/fa";
import { SERVER } from "./ServerUrls";
import axios from "axios";
import { toast } from "react-toastify";

const MiningTemp = () => {
  const [username, setUsername] = useState("");
  const [errorsname, setErrorsName] = useState("");
  const [errorsNa, setErrorsNa] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [errorscon, setErrorsCon] = useState(false);
  const [errorscontact, setErrorsContact] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [message, setMessage] = useState("");
  const [serviceitems, setServiceItems] = useState([]);
  const handlerChange = (event) => {
    // props.onChangeSelected(event.target.value)
    setServiceId(event.target.value);
    // console.log(serviceId);
  };
  const getAllServices = () => {
    axios
      .get(SERVER + "/getAllServices", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("Get All Services->", res.data.data);
        setServiceItems(res.data.data);
      });
  };
  useEffect(() => {
    getAllServices();
  }, []);
  // Form Validation
  const onchangeFullName = (e) => {
    const username = e.target.value.replace(/[^a-z]/gi, " ");
    setUsername(username);
    if (username.length <= 5) {
      setErrorsName("Enter Name");
      setErrorsNa(true);
      return username;
    } else {
      setErrorsName(false);
    }
  };
  const onchangeContact = (e) => {
    // console.log("onchangeContact");
    const contactNumber = e.target.value.replace(/([^0-9])+/i, "");
    setContactNumber(contactNumber);
    // (contact.length < 10 || contact.length > 10)
    if (contactNumber.length < 10) {
      setErrorsContact("Enter valid Contact");
      setErrorsCon(true);
    } else {
      setErrorsCon(false);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const insertData = {
      fullname: username,
      email: email,
      // subject: subject,
      contact_number: contactNumber,
      myservice_id: serviceId,
      message: message,
      obtained_from: "Template",
    };
    console.log("befor", insertData);
    axios
      .post(SERVER + "/insertQuoteEnquiry", insertData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("Insert Enquiry Res", res);
        console.log("afterrun api", res.data);
        toast.success("Thank You ");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((err) => {
        console.log("not post", err);
        toast.error("something wrong");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };
  return (
    <>
      <div>
        {/* <Salon /> */}
        <div>
          {/* Header start  */}
          <div
            className="selection:bg-Green selection:text-white mx-auto w-full 
          md:h-[35rem]  h-96 py-10 px-4 bg-Hero bg-cover md:bg-top bg-center"
          >
            {/* Content Section */}
            <div className="flex flex-col justify-center text-center items-center md:h-[30rem] h-96 mx-auto">
              <h1 className="text-white uppercase font-bold py-2 md:text-4xl text-xl mb-3">
                Mining
              </h1>
              <p className=" text-center text-white font-subheading   md:text-lg text-xs md:w-[980px] max-w-fit">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s
              </p>
            </div>
            {/* Content Section Ends*/}
          </div>

          {/* Header End  */}

          {/* Heading start  */}
          <div>
            <div className="font-bold md:text-4xl text-lg text-center uppercase  md:mt-5 md:my-2">
              Mining
            </div>
            <div className="flex justify-center ">
              <hr className="h-1 bg-pink-600 md:w-[100px]  w-[100px] text-center"></hr>
              <hr className="w-5 h-5 rounded-full bg-pink-600 -mt-2"></hr>
              <hr className="h-1 bg-pink-600 md:w-[100px]  w-[100px] text-center"></hr>
            </div>
          </div>

          {/* Heading End  */}

          {/* card start  */}

          <div className="flex justify-center md:my-10 my-5 md:mx-5 mx-10  ">
            <div class="flex gap-10 justify-evenly  flex-wrap">
              <Link
                class="relative block w-[350px] h-[350px] bg-gray-900 group rounded-lg"
                // to="/Gym"
                to="/Mining-technology"
                target="_blank"
              >
                <img
                  className="absolute inset-0 object-contain 
                               group-hover:opacity-50 w-[350px] h-[350px]"
                  src={main}
                  alt=""
                />
                <div class="relative p-5">
                  <div class="mt-28">
                    <div
                      class="transition-all transform 
                               opacity-0 
                              group-hover:opacity-100 
                              group-hover:translate-y-0"
                    >
                      <div class="p-2 text-center">
                        <p class="text-lg text-white">
                          Mining-technology Template
                        </p>{" "}
                        <small className="text-lg text-white">
                          React & Tailwind CSS
                        </small>
                        <br />
                        <button
                          class="px-4 py-2 text-sm 
                                          text-white bg-green-600"
                        >
                          <AiOutlineArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                class="relative block w-[350px] h-[350px] bg-gray-900 group rounded-lg"
                to="/mining"
                target="_blank"
              >
                <img
                  className="absolute inset-0 object-contain 
                               group-hover:opacity-50 w-[350px] h-[350px]"
                  src={minings}
                  alt="pj"
                />
                <div class="relative p-5">
                  <div class="mt-28">
                    <div
                      class="transition-all transform 
                               opacity-0 
                              group-hover:opacity-100 
                              group-hover:translate-y-0"
                    >
                      <div class="p-2 text-center">
                        <p class="text-lg text-white">Mining Template</p>{" "}
                        <small className="text-lg text-white">
                          React & Tailwind CSS
                        </small>
                        <br />
                        <button
                          class="px-4 py-2 text-sm 
                                          text-white bg-green-600"
                        >
                          <AiOutlineArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                class="relative block w-[350px] h-[350px] bg-gray-900 group rounded-lg"
                to="/mining-customer-management-solution"
                target="_blank"
              >
                <img
                  className="absolute inset-0 object-contain 
                               group-hover:opacity-50 w-[350px] h-[350px]"
                  src={mining}
                  alt="pj"
                />
                <div class="relative p-5">
                  <div class="mt-28">
                    <div
                      class="transition-all transform 
                               opacity-0 
                              group-hover:opacity-100 
                              group-hover:translate-y-0"
                    >
                      <div class="p-2 text-center">
                        <p class="text-lg text-white">
                          mining-customer-management Template
                        </p>{" "}
                        <small className="text-lg text-white">
                          React & Tailwind CSS
                        </small>
                        <br />
                        <button
                          class="px-4 py-2 text-sm 
                                          text-white bg-green-600"
                        >
                          <AiOutlineArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          {/* card End  */}
          {/* footer start md:my-10 my-5 md:mx-5 mx-10*/}
          <div className="mt-8 p-8  bg-Bgfooter bg-cover  bg-center">
            <div
              className="flex justify-between gap-16 
          mx-auto w-[90%] flex-wrap lg:flex-nowrap mt-5 pt-12 "
            >
              <div className="md:w-[20%] w-[100%]">
                <img src={logofoter} alt="img" />
                <h1 className="uppercase text-xl font-bold underline">
                  about us
                </h1>
                <p className=" font-semibold mt-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
                  debitis, blanditiis, natus totam ducimus accusantium
                  perspiciatis voluptatum ullam soluta non sit tenetur incidunt
                  delectus corporis? Neque expedita explicabo inventore eos.
                </p>
              </div>
              <div className="md:w-[30%] w-[100%] ">
                <form
                  className="border-2 py-8 p-5 border-black rounded-md relative"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <div className="flex flex-col ">
                    <div className=" gap-8 items-baseline">
                      {/* <label className="text-base  font-semibold">Name</label> */}
                      <input
                        type="text"
                        className="border-0 bg-transparent  border-b-2 border-gray 
                      focus:outline-none w-[90%]"
                        value={username}
                        onChange={onchangeFullName}
                        placeholder=" Full Name"
                      />

                      {errorsNa ? (
                        <div className="text-left text-red-500">
                          {errorsname}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className=" gap-8 items-baseline mt-4">
                      {/* <label className="text-base  font-semibold">E-mail</label> */}
                      <input
                        type="email"
                        className="border-0 bg-transparent border-b-2 border-gray 
                      focus:outline-none w-[90%]"
                        placeholder="E-mail"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="gap-8 items-baseline mt-4">
                      {/* {" "}
                      <label className="text-base  font-semibold">
                        Contact Number
                      </label>{" "} */}

                      <input
                        type="text"
                        className="border-0 bg-transparent  border-b-2 border-gray
                         focus:outline-none w-[90%]"
                        value={contactNumber}
                        onChange={onchangeContact}
                        maxLength={10}
                        placeholder=" Contact Number"
                      />
                      <br></br>
                      {errorscon ? (
                        <div className="text-left text-red-500">
                          {errorscontact}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className=" gap-8 items-baseline mt-4">
                      {/* <label className="text-base  font-semibold">
                      Select Enquiry For
                    </label> */}
                      <div className="w-full">
                        <select
                          className="border-0 bg-transparent  border-b-2 border-gray
             focus:outline-none w-[90%]"
                          onChange={(e) => {
                            handlerChange(e);
                            // hadalChnageID();
                          }}
                        >
                          <option> Select Contact For</option>

                          {serviceitems ? (
                            serviceitems.map((item) => (
                              <option
                                key={item.service_name}
                                value={item.myservices_id}
                              >
                                {item.service_name}
                              </option>
                            ))
                          ) : (
                            <>
                              <option value="">No Service</option>
                              <p>no service</p>
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="flex flex-row gap-8 items-baseline mt-4">
                      {/* <label className="text-base font-semibold">Message</label> */}
                      <input
                        type="text"
                        className="border-0 bg-transparent border-b-2 border-gray  
                      focus:outline-none w-[90%]"
                        placeholder="Write Your Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className="mt-5 flex justify-center items-center">
                      <input
                        type="submit"
                        value="Submit"
                        disabled={
                          username === "" || contactNumber === "" ? true : false
                        }
                        className="rounded-full bg-bluedark bg-blue-600 text-white py-2 px-10"
                      />

                      {/* <button
                    type="submit"
                  >
                    Send
                  </button> */}
                    </div>
                  </div>
                  <div
                    className="absolute top-[-20px] items-center z-50
                 bg-blue-100 
                rounded-lg px-2 text-center left-14 right-14"
                  >
                    <h1 className="text-xl font-bold">Get in touch</h1>
                  </div>
                </form>
              </div>
              <div className="md:w-[25%] w-[100%]">
                {" "}
                <h1 className="uppercase text-xl font-bold underline">
                  Industries
                </h1>
                <ul className="mt-3">
                  <Link to="https://agriculture.cerbosys.com" target="_blank">
                    <li className=" font-semibold">Agriculture</li>
                  </Link>
                  <Link to="https://ecommerce.cerbosys.com" target="_blank">
                    <li className=" font-semibold">E-commerce & Retail</li>
                  </Link>
                  <Link to="https://healthcare.cerbosys.com" target="_blank">
                    <li className=" font-semibold">
                      Healthcare & life Science
                    </li>
                  </Link>
                  <Link to="https://banking.cerbosys.com" target="_blank">
                    <li className=" font-semibold">Banking & Finance </li>
                  </Link>
                  <Link to="https://mining.cerbosys.com" target="_blank">
                    <li className=" font-semibold">Mining</li>
                  </Link>
                  <Link to="https://transport.cerbosys.com" target="_blank">
                    <li className=" font-semibold">Transport & Logistics</li>
                  </Link>
                  <Link to="https://education.cerbosys.com" target="_blank">
                    <li className=" font-semibold">Education</li>
                  </Link>
                  <Link to="https://realestate.cerbosys.com" target="_blank">
                    <li className=" font-semibold">
                      Real Estate & Construction
                    </li>
                  </Link>
                  <Link to="https://manufacturing.cerbosys.com" target="_blank">
                    <li className=" font-semibold">Manufacturing</li>
                  </Link>
                </ul>
              </div>
              <div className="md:w-[25%] w-[100%]">
                {" "}
                <h1 className="uppercase text-xl font-bold underline">
                  Contact
                </h1>
                <p className=" font-semibold mt-3">
                  {" "}
                  <span className="mr-2">
                    <HiLocationMarker className="inline text-xl " />
                  </span>
                  15-16, Lakshya Vihar , Vaibhav
                  <br></br> Nagar, Indore, (MP) 452016{" "}
                </p>
                <p className="font-semibold mt-3">
                  {" "}
                  <span className="mr-2">
                    <BsTelephoneOutboundFill className="inline text-xl " />
                  </span>
                  07314993444
                </p>
                <p className="font-semibold mt-3">
                  {" "}
                  <span className="mr-2">
                    <RiWhatsappLine className="inline text-xl " />
                  </span>
                  07314993444
                </p>
                <p className=" font-semibold mt-3">
                  {" "}
                  <span className="mr-2 ">
                    <AiOutlineMail className="inline text-xl " />
                  </span>
                  07314993444
                </p>
                <div className="flex mt-3 w-[60%] justify-between">
                  <div className="w-[30px] h-[30px] rounded-full bg-greydak flex justify-center items-center">
                    <CiFacebook className="z-40 text-xl text-white" />
                  </div>
                  <div className="w-[30px] h-[30px] rounded-full bg-greydak flex justify-center items-center">
                    <FaInstagramSquare className="z-40 text-xl text-white" />
                  </div>
                  <div className="w-[30px] h-[30px] rounded-full bg-greydak flex justify-center items-center">
                    <AiFillTwitterSquare className="z-40 text-xl text-white" />
                  </div>
                  <div className="w-[30px] h-[30px] rounded-full bg-greydak flex justify-center items-center">
                    <AiFillLinkedin className="z-40 text-xl text-white" />
                  </div>
                  <div className="w-[30px] h-[30px] rounded-full bg-greydak flex justify-center items-center">
                    <AiFillYoutube className="z-40 text-xl text-white" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* footer end */}
        </div>
      </div>
    </>
  );
};

export default MiningTemp;
