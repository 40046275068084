import React from "react";

import workImg from "../images/work.png";
import workImg1 from "../images/work1.png";
import workImg2 from "../images/work3.png";
import arrowImg from "../images/working-process-arrow-1.png.png";
import arrowImg2 from "../images/working-process-arrow-2.png.png";

const Process = () => {
  return (
    <div className="bg-backg py-14 px-3 h-[600px]">
      <div className="w-[90%] mx-auto">
        {/* <div className="working-process-shape"></div> */}

        <div className="">
          <div className="section-content mb-0 mb-md-6">
            <h4 className="text-lg font-bold text-orange ">WORKING PROCESS</h4>
            <h2 className="text-7xl mt-4 font-bold text-coco">HOW IT WORKS?</h2>
          </div>

          <div className="mt-16 flex  relative md:visible invisible">
            <div className="absolute top-40">
              <div className="bg-white relative rounded-full w-[200px] h-[200px]">
                <div className="absolute top-[-20px] left-[10px] right-[10px]">
                  <img className="mx-auto " src={workImg} alt="" />
                </div>
                <div className="absolute bottom-10 left-[10px] right-[10px]">
                  <h3 className="text-center text-xl mt-4 font-bold">
                    Planing
                  </h3>
                </div>
              </div>
            </div>
            <div className="absolute top-20 left-[40%]">
              <div className="bg-white relative rounded-full w-[200px] h-[200px]">
                <div className="absolute top-[-20px] left-[10px] right-[10px]">
                  <img className="mx-auto" src={workImg1} alt="" />
                </div>
                <div className="absolute bottom-10 left-[10px] right-[10px]">
                  <h3 className="text-center text-xl mt-4 font-bold">
                    Development
                  </h3>
                </div>
              </div>
            </div>
            <div className="absolute top-0 left-[80%]">
              <div className="bg-white relative rounded-full w-[200px] h-[200px]">
                <div className="absolute top-[-20px] left-[10px] right-[10px]">
                  <img className="mx-auto" src={workImg2} alt="" />
                </div>
                <div className="absolute bottom-10 left-[10px] right-[10px]">
                  <h3 className="text-center text-xl mt-4 font-bold">Launch</h3>
                </div>
              </div>
            </div>

            <div className="absolute top-40 left-[22%]">
              <img src={arrowImg} alt="" />
            </div>
            <div className="absolute top-28 left-[62%]">
              <img src={arrowImg2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
