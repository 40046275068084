import React from "react";
import Header from "./components/Header";
import Solution from "./components/Solution";
import About from "./components/About";
import Service from "./components/Service";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import Customer from "./components/Customer";
import Process from "./components/Process";
import { Helmet } from "react-helmet";

const Cermining = () => {
  return (
    <div>
      <div>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Mining || Mining-technology  </title>
      </Helmet>
     
      <Header />
      <Solution />
      <About />
      <Service />
      <Process />
      <Portfolio />
      <Customer />
      <Footer />
    </div>
    </div>
  );
};

export default Cermining;
