import React from "react";
import logo from "../images/logo.png";

const Navbar = () => {
  return (
    <>
      <header className="w-[90%] mx-auto overflow-hidden">
        <div className="flex  gap-40 items-center" id="navbarNavDropdown">
          <div className="lg:w-[20%] w-[100%]">
            <img src={logo} alt="" className="lg:w-[60%] w-[100%]" />
          </div>
          <div className="bg-redd h-24 md:w-[80%] w-[0px] invisible lg:visible ">
            <ul className="flex gap-12 h-full text-lg font-bold justify-center items-center text-white  text-center mx-auto">
              <li className="bg-white p-3 text-redd underline">
                {" "}
                <a href="#fd">Home</a>
              </li>
              <li className="underline">
                <a href="#f">Features</a>
              </li>
              <li className="underline">
                {" "}
                <a href="#f">Pages</a>
              </li>
              <li className="underline">
                <a href="#f">Shop</a>
              </li>
              <li className="underline">
                {" "}
                <a href="#f">Blog</a>
              </li>

              <li className="underline">
                <a href="#e">Element</a>
              </li>

              <li className="underline">
                <a href="#r">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {/* backgorund img */}
      <div className="bg-bgmining bg-cover bg-center bg-no-repeat h-[600px]"></div>
    </>
  );
};

export default Navbar;
