import React from "react";
import about from "../images/about.png";

const About = () => {
  return (
    <>
      <div className=" bg-white pt-[90px] w-[90%] mx-auto">
        <div className="">
          <div className="flex md:flex-nowrap flex-wrap justify-between items-center">
            <div className="col-md-7 mt-2">
              <div className="titlepage max-w-[666px] w-[100%] textleft pb-0">
                <h2 className="text-4xl font-bold mb-5">About Our Company</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomisedThere are many
                  variations of passages of Lorem Ipsum available, but the
                  majority have suffered alteration in some form, by injected
                  humour, or randomised
                </p>
                <button className="py-3 mt-5 text-white rounded-lg px-7 bg-darkgray hover:bg-oranges">
                  Read More
                </button>
              </div>
            </div>
            <div className="col-md-5 mt-2">
              <div className="about_img">
                <figure>
                  <img src={about} alt="#" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
