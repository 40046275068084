import React from "react";
import IMG1 from "../images/IMG1.png";
import IMG2 from "../images/IMG2.png";
import IMG3 from "../images/IMG3.png";
import IMG4 from "../images/IMG4.png";

const Stafe = () => {
  return (
    <>
      <div className="staff_main w-[90%] mx-auto block mt-25 ">
        <div className="">
          <div className="">
            <div className="mt-12 md:pt-20 pt-0">
              <div className="titlepage text-center">
                <h2 className="text-5xl font-bold">Our Expert Staff</h2>
                <p className="text-lg font-subheading mt-4">
                  There are many variations of passages of Lorem Ipsum
                  available, but the{" "}
                </p>
              </div>
            </div>
            <div className="flex-wrap md:flex-nowrap flex justify-between gap-8 mt-10">
              <div className="col-md-3 col-sm-6">
                <div className="staff">
                  <div className="man">
                    <img className="imag" src={IMG1} alt="#" />
                  </div>
                  <div className=" bg-oranges p-2 rounded-md text-white text-center font-bold">
                    <h4>Prasang</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="staff">
                  <div className="man">
                    <img className="imag" src={IMG2} alt="#" />
                  </div>
                  <div className=" bg-oranges p-2 rounded-md text-white text-center font-bold">
                    <h4>Shubham</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="staff">
                  <div className="man">
                    <img className="imag" src={IMG3} alt="#" />
                  </div>
                  <div className="bg-oranges p-2 rounded-md  text-white text-center font-bold">
                    <h4>Omprakash</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="staff">
                  <div className="man">
                    <img className="imag" src={IMG4} alt="#" />
                  </div>
                  <div className=" bg-oranges p-2 rounded-md text-white text-center font-bold">
                    <h4>Rishabh</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center mt-5">
              <button className="py-3 mt-5 text-white rounded-lg px-7 bg-darkgray hover:bg-oranges">
                See More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stafe;
