import React from "react";
import Navbar from "./components/Navbar";
import Solutions from "./components/Solutions";
import Quality from "./components/Quality";
import Problem from "./components/Problem";
import Team from "./components/Team";
import Center from "./components/Center";
import Tetimonial from "./components/Tetimonial";
import News from "./components/News";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

const Cermomin = () => {
  return (
    <div>
       <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Mining || mining-customer-management-solution  </title>
      </Helmet>
    <div>
      <Navbar />
      <Solutions />
      <Quality />
      <Problem />
      <Team />
      <Center />
      <Tetimonial />
      <News />
      <Footer />
    </div>
    </div>
  );
};

export default Cermomin;
