import React from "react";
import logoImg from "../images/logo (1).png";
import logoImg1 from "../images/logo (2).png";
import logoImg2 from "../images/logo3.png";
import project from "../images/project.png";
import project2 from "../images/project2.png";
import project3 from "../images/project3.png";
// import project3 from "../images/project3.png ";

// import displayImg from "../images/project-display-1.png";
// import displayImg1 from "../images/project-display-3.png.png";
// // import displayImg2 from "../images/project-display-2.png.png";
// import displayImg3 from "../images/project-display-4.png.png";

const Portfolio = () => {
  return (
    <>
      <div classNameName="w-[90%] mx-auto ">
        <div className="mt-16">
          <div className="flex flex-wrap md:flex-nowrap items-center">
            <div className="flex mt-5 items-center gap-7 border-gery border-r-[1px] justify-center md:w-[33%] w-[100%]">
              <div className="icon">
                <img src={logoImg} alt="" />
              </div>
              <div className="content">
                <h2 className="font-bold text-5xl mb-3 text-coco">
                  <span className="counter">2,589</span>
                </h2>
                <span className="font-heading  text-gery">Happy Clients</span>
              </div>
            </div>

            <div className="flex mt-5 items-center gap-7 justify-center border-gery border-r-[1px] md:w-[33%] w-[100%]">
              <div className="icon">
                <img src={logoImg1} alt="" />
              </div>
              <div className="content">
                <h2 className="font-bold mb-3 text-5xl text-coco">
                  <span className="counter">589</span>K
                </h2>
                <span className="font-heading text-gery">Projects Done</span>
              </div>
            </div>

            <div className="flex mt-5 items-center gap-7 justify-center border-gery border-r-[1px] md:w-[33%] w-[100%]">
              <div className="icon">
                <img src={logoImg2} alt="" />
              </div>
              <div className="content">
                <h2 className="font-bold mb-3 text-5xl text-coco">
                  <span className="counter">3,462</span>
                </h2>
                <span className="font-heading text-gery">Cup of coffee</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-28">
          <div className=" text-center">
            <h4 className="text-lg font-bold text-orange text-center">
              OUR PORTFOLIO
            </h4>
            <h2 className="md:text-7xl text-2xl mt-4 font-bold text-coco text-center">
              SOME PROJECT’S
            </h2>
          </div>

          <div className="flex gap-20 md:flex-nowrap flex-wrap justify-center items-center mt-20">
            <div>
              <img src={project} alt="iufm" />
            </div>
            <div>
              <img src={project2} alt="iufm" />
            </div>
            <div>
              <img src={project3} alt="iufm" />
            </div>
          </div>

          <div className="text-center mt-12">
            <button className="text-white hover:text-black font-bold bg-gradient-to-r from-orange to-oranges px-5 py-3 rounded-3xl">
              {" "}
              All Projects
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
