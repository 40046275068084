import React from "react";
import project_img from "../images/project_img.png";
import project_img1 from "../images/project_img1.png";
import project_img2 from "../images/project_img2.png";
const Project = () => {
  return (
    <>
      <div className=" w-[90%] mx-auto max-h-fit ">
        <div className="">
          <h2 className="text-4xl font-bold mb-5 text-center">
            Our Features Projects
          </h2>
          <p className="text-center">
            It is a long established fact that a reader will be distracted by
            the readable
          </p>
        </div>

        <div className="mt-8 justify-between md:flex-nowrap flex-wrap  gap-16 flex">
          <div className=" sm:w-[33%] w-[100%] relative">
            <div className=" relative  bg-white ">
              <div className="project_img">
                <img src={project_img} alt="" />
              </div>
              <div className="absolute md:visible invisible bottom-[-5px] bg-black text-white text-center opacity-75 p-2">
                <h3 className="text-xl font-bold mb-3">
                  Reader will be
                  <br /> distracted by the readable
                </h3>
                <p className="text-justify">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised
                </p>
              </div>
            </div>
          </div>
          <div className=" sm:w-[33%] w-[100%] relative">
            <div className=" bg-white relative ">
              <div className="project_img">
                <img
                  src={project_img1}
                  alt=""
                  // className="w-full h-full"
                />
              </div>
              <div className="absolute md:visible invisible bottom-[-5px] bg-black text-white text-center opacity-75 p-2">
                <h3 className="text-xl font-bold mb-3">
                  Reader will be
                  <br /> distracted by the readable
                </h3>
                <p className="text-justify">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised
                </p>
              </div>
            </div>
          </div>
          <div className=" sm:w-[33%] w-[100%] relative">
            <div className=" bg-white relative">
              <div className="project_img">
                <img
                  src={project_img2}
                  alt=""
                  // className="w-full h-full"
                />
              </div>
              <div className="absolute md:visible invisible bottom-[-5px] bg-black text-white text-center opacity-75 p-2">
                <h3 className="text-xl font-bold mb-3">
                  Reader will be
                  <br /> distracted by the readable
                </h3>
                <p className="text-justify">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
