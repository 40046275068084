import React from "react";
import first from "../images/a (4).jpg";
import second from "../images/a (3).jpg";
import third from "../images/a (2).jpg";
import four from "../images/a (2).jpg";
const Team = () => {
  return (
    <>
      <div className="mt-12 w-[90%] mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold ">Our Team</h2>
          <p className="mt-3">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been<br></br> the industry's standard
            dummy text ever since the.
          </p>
        </div>
        <div className="flex mt-10 md:flex-nowrap flex-wrap gap-12 items-center">
          <div className="md:w-[25%] w-[100%]">
            <div className="relative">
              <div>
                <img src={first} alt="img" className="w-full h-full" />
              </div>

              <div className="absolute bottom-0 top-0  bg-redd w-[50px] h-[100%]">
                <h2 className="text-white uppercase rotate-90 text-md font-bold ">
                  Nashid martines
                </h2>
              </div>
            </div>
          </div>
          {/*  Nashid martines */}
          <div className="md:w-[25%] w-[100%]">
            <div className="relative">
              <div>
                <img src={second} alt="img" className="w-full h-full" />
              </div>
              <div className="absolute bottom-0 top-0  bg-redd w-[50px] h-[100%]">
                <h2 className="text-white uppercase rotate-90 text-md font-bold  ">
                  konne backfield
                </h2>
              </div>
            </div>
          </div>
          <div className="md:w-[25%] w-[100%]">
            <div className="relative">
              <div>
                <img src={third} alt="img" className="w-full h-full" />
              </div>
              <div className="absolute bottom-0 top-0  bg-redd w-[50px] h-[100%]">
                <h2 className="text-white uppercase rotate-90 text-md font-bold ">
                  hackson willingham
                </h2>
              </div>
            </div>
          </div>
          <div className="md:w-[25%] w-[100%]">
            <div className="relative">
              <div>
                <img src={four} alt="img" className="w-full h-full" />
              </div>
              <div className="absolute bottom-0 top-0  bg-redd w-[50px] h-[100%]">
                <h2 className="text-white uppercase rotate-90 text-md font-bold ">
                  konne backfield
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
