import React from "react";
import logo from "../images/logo.png";
import { AiOutlineSearch } from "react-icons/ai";

import ser1 from "../images/ser1.png";
import ser2 from "../images/ser2.png";
import ser3 from "../images/ser3.png";

const Navbar = () => {
  return (
    <>
      <header className="full_bg h-[750px]  bg-cover bg-center bg-no-repeat bg-headerbg relative">
        <div className="header w-full h-[116px] flex justify-center items-center">
          <div className="max-w-[1170px]">
            <div className="row">
              <div className="col-md-12">
                <div className=" md:visible invisible header_bottom w-[70%] mx-auto left-10 right-10 bg-white  top-12 p-4 absolute rounded-md">
                  <div className="flex justify-between gap-20 items-center align-bottom ">
                    <div>
                      <img src={logo} alt="img" className="w-[300px]" />
                    </div>
                    <div className="w-full  md:visible invisible">
                      <ul className="flex justify-between items-center w-full">
                        <li className="bg-oranges px-4 py-2 rounded-lg text-white font-bold">
                          Home
                        </li>
                        <li className="font-bold">About</li>
                        <li className="font-bold">Project</li>
                        <li className="font-bold">Staff</li>
                        <li className="font-bold">Contact Us</li>
                        <li className="font-bold text-xl">
                          <AiOutlineSearch />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="banner_main absolute md:w-[50%] w-[100%] mx-auto left-[10px] right-[10px] top-[50%]">
          <div
            id="myCarousel"
            className="carousel slide banner"
            data-ride="carousel"
          >
            {/* <div className="carousel-caption  banner_po"> */}
            <div className="row text-white">
              <div className="col-md-9">
                <div className="build_box">
                  <h1 className="text-5xl font-bold mb-5">
                    We Are Mining Experts
                  </h1>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority
                    <br /> There are many variations of passages of
                  </p>
                  <button className="bg-oranges px-8 mt-6 py-2 rounded-lg text-white font-bold">
                    Contact Us
                  </button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </section>
      </header>
      {/* bottom-box in banner page */}
      <div className="three_box absolute bottom-[-70px] w-[60%] left-20 md:visible invisible">
        <div className="row flex  w-full gap-4 ">
          <div className="bg-white p-8 rounded-md  hover:bg-yellow">
            <div id="text_hover" className="items-center">
              <i>
                <img src={ser1} alt="#" className="mx-auto mb-4" />
              </i>
              <span className="font-heading text-xl">
                construction services
              </span>
            </div>
          </div>
          <div className="bg-white p-8 rounded-md  hover:bg-yellow">
            <div id="text_hover" className="const text_align_center">
              <i>
                <img src={ser2} alt="#" className="mx-auto mb-4" />
              </i>
              <span className="font-heading">RECONSTRUCTION</span>
            </div>
          </div>
          <div className="bg-white p-8 rounded-md  hover:bg-yellow">
            <div id="text_hover" className="const text_align_center">
              <i>
                <img src={ser3} alt="#" className="mx-auto mb-4" />
              </i>
              <span className="font-heading">ELECTRICAL services</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
