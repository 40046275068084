import React from "react";
import image_1 from "../images/image_1.jpg";
import pic2 from "../images/pic2.jpg"
import image_2 from "../images/image_2.jpg"

const News = () => {
  return (
    <>
      <div className="mt-12 w-[90%] mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold ">Latest News</h2>
          <p className="mt-3">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been<br></br> the industry's standard
            dummy text ever since the.
          </p>
          {/* <div className="bg-newback bg-cover h-[1300px]"></div> */}
          {/*start first img div */}
          <div className="shadow-lgpb-5">
            {" "}
            <img src={image_1} alt="img" className="w-full h-full p-3" />
            <div className="text-start px-6">
              {" "}
              <p className="text-redd font-bold">10 AUG 2016 BY JACK</p>
              <h2
                className="text-2xl underline font-bold mt-3
              "
              >
                Seven Doubts You Should
              </h2>
              <p className="text-gery mt-3 ">
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true.
              </p>
              <button
                className="text-white mt-4 bg-redd font-bold rounded py-2 px-4 underline
            hover:underline-offset-0"
              >
                Read More
              </button>
            </div>
          </div>
          {/*end first img div */}
          {/* second img div */}
          <div className="shadow-lg pb-5">
            {" "}
            <img src={pic2} alt="img" className="w-full h-full p-3" />
            <div className="text-start px-6">
              {" "}
              <p className="text-redd font-bold">11 AUG 2016 BY ZACHARY</p>
              <h2
                className="text-2xl underline font-bold mt-3
              "
              >
                The Shocking Revelation
              </h2>
              <p className="text-gery mt-3 ">
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true.
              </p>
              <button
                className="text-white mt-4 bg-redd font-bold rounded py-2 px-4 underline
            hover:underline-offset-0"
              >
                Read More
              </button>
            </div>
          </div>
          {/* end second img div */}
          {/* third img div start */}
          <div className="shadow-lg pb-5">
            {" "}
            <img src={image_2} alt="img" className="w-full h-full p-3" />
            <div className="text-start px-6">
              {" "}
              <p className="text-redd font-bold">12 AUG 2016 BY CHARLOTTE</p>
              <h2
                className="text-2xl underline font-bold mt-3
              "
              >
              The Story Of Industry
              </h2>
              <p className="text-gery mt-3 ">
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true.
              </p>
              <button
                className="text-white mt-4 bg-redd font-bold rounded py-2 px-4 underline
            hover:underline-offset-0"
              >
                Read More
              </button>
            </div>
          </div>
            {/* third img div end */}

        </div>
      </div>
    </>
  );
};

export default News;
