import React from "react";
import man from "../images/a (3).jpg";

const Tetimonial = () => {
  return (
    <>
      <div className="bg-backg py-20 md:px-16 px-4">
        <div className="mt-12 w-[90%] mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-bold ">Testimonials</h2>
            <p className="mt-3">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been<br></br> the industry's standard
              dummy text ever since the.
            </p>
          </div>
          <div className="bg-white shadow-lg mt-12 ">
            <div className="flex md:flex-nowrap flex-wrap justify-center items-center">
              <div className="w-[100%] md:w-[30%] p-5">
                <img src={man} alt="img" className="p-6 w-[300px]" />
              </div>
              <div className="w-[100%] md:w-[70%]md:p-14 p-2">
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the when an printer took a galley of type and scrambled
                  it to make.
                </p>
               
                <h2 className="text-sky text-2xl font-bold mt-3 mb-3">David Matin</h2>
             <p>Student</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tetimonial;
