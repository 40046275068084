import React from "react";
import headerim from "../images/headerim.png";

const Header = () => {
  return (
    <header className=" bg-headback overflow-hidden h-[830px]  bg-cover  top-0 left-0 w-[100%] z-20">
      <div className="bg-oranges w-[90%] mx-auto">
        {" "}
        <div className="flex p-7 bg-oranges justify-between gap-32 items-center ">
          <div className="logo">
            <a href="#f">
              <img src={headerim} alt="" />
            </a>
          </div>

          <div className="w-full md:visible invisible">
            <ul className="flex text-white font-bold justify-evenly items-center">
              <li>
                <a href="#f">Home</a>
              </li>
              <li className="has-dropdown">
                <a href="#b">Service</a>
              </li>
              <li className="has-dropdown">
                <a href="b#">Project</a>
              </li>
              <li className="has-dropdown">
                <a href="#b">Blog</a>
              </li>
              <li className="has-dropdown">
                <a href="#b">Pages</a>
              </li>
              <li>
                <a href="contact.html">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="absolute text-center bottom-48 text-white items-center left-[10px] right-[10px] mx-auto">
        <p className="text-center font-bold md:text-7xl text-2xl">
          BEST INDUSTRIAL
        </p>
        <p className="text-center font-bold md:text-4xl text-2xl mt-2 ">
          {" "}
          SOLUTION FOR YOUR COMPANY
        </p>
      </div>
    </header>
  );
};

export default Header;
