import React from "react";
import divImg from "../images/div.png";
import divImg1 from "../images/div (3).png";
import divImg2 from "../images/div (4).png";

const Solution = () => {
  return (
    <>
      <div className="mt-12 w-[90%] mx-auto">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-10">
          <div className="md:w-[33%] w-[100%]  text-center ">
            <div className="bg-backg rounded-lg py-10 px-7 hover:bg-gradient-to-r from-orange to-oranges">
              <div className="">
                <img src={divImg} alt=" " className="mx-auto" />
              </div>
              <div className="content">
                <h4 className="text-2xl font-bold mt-4">Smart Solution</h4>
                <p className="text-sm font-heading mt-3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>

          <div className="md:w-[33%] w-[100] text-center ">
            <div className="bg-backg rounded-lg py-10 px-7 hover:bg-gradient-to-r from-orange to-oranges">
              <div className="icon">
                <img src={divImg1} alt="" className="mx-auto" />
              </div>
              <div className="content">
                <h4 className="text-2xl font-bold mt-4">Award Winning</h4>
                <p className="text-sm font-heading mt-3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>

          <div className="md:w-[33%] w-[100]  text-center">
            <div className="bg-backg rounded-lg py-10 px-7 hover:bg-gradient-to-r from-orange to-oranges">
              <div className="icon">
                <img src={divImg2} alt="" className="mx-auto" />
              </div>
              <div className="content">
                <h4 className="text-2xl font-bold mt-4">Great Support</h4>
                <p className="text-sm font-heading mt-3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution;
