import React from "react";
import pic10 from "../images/pic10.jpg";

const Problem = () => {
  return (
    <>
      <div className=" mt-12">
        <div className="flex gap-5 md:flex-nowrap flex-wrap justify-center items-center">
          <div className="bg-redd md:py-20 py-2 md:px-24 px-2 text-white md:h-[500px] h-[550px]  md:w-[50%] w-[100%]">
            <h2 className="text-5xl font-heading">
              Talk to us about solving your mining problems
            </h2>
            <p className="text-justify mt-10">
            Praesent pharetra orci odio, ut mattis tellus ullamcorper ornare. Suspendisse ullamcorper metus in erat viverra, vehicula pharetra dolor accumsan. In arcu ex, rutrum finibus malesuada vel. Praesent pharetra orci odio, ut mattis tellus ullamcorper ornare. Suspendisse ullamcorper metus in erat viverra, vehicula pharetra dolor accumsan. In arcu ex, rutrum finibus malesuada vel.
            </p>
            <button className="text-gery mt-10 underline py-2 px-6 bg-white">
              View Project
            </button>
          </div>
          <div className="h-[500px] md:w-[50%] w-[100%]">
            <img src={pic10} alt="img" className="h-full w-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Problem;
