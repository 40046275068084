import React from "react";

const Center = () => {
  return (
    <>
      <div className="md:h-[200px] h-[300px] bg-centerbg  relative ">
        <div className="absolute h-full w-full top-0 left-0 right-0 bottom-0 bg-darkgray opacity-75"></div>
        <div className="absolute left-[10px] right-[10px] px-10 flex flex-wrap md:flex-nowrap justify-center items-center h-full w-[90%] mx-auto">
          <div className="text-white">
            {" "}
            <h2 className="text-5xl font-bold z-50 text-center">7652</h2>
            <p className="text-md- fond-bold mt-2 text-center">
              Completed Projects
            </p>
          </div>
          <div className="text-white">
            {" "}
            <h2 className="text-5xl font-bold z-50 text-center">4562</h2>
            <p className="text-md- fond-bold mt-2 text-center">Happy Clients</p>
          </div>
          <div className="text-white">
            {" "}
            <h2 className="text-5xl font-bold z-50 text-center">3569</h2>
            <p className="text-md- fond-bold mt-2 text-center">
              Questions Answered
            </p>
          </div>
          <div className="text-white">
            {" "}
            <h2 className="text-5xl font-bold z-50 text-center">2089</h2>
            <p className="text-md- fond-bold mt-2 text-center">
              Ordered Coffee's
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Center;
