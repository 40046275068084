import React from "react";
import chImg from "../images/ch1.png";
import chImg1 from "../images/ch2.png";
import chImg2 from "../images/ch3.png";
const Choice = () => {
  return (
    <div className="choose bg-bgcenter bg-cover flex justify-centeritems-center mt-12 h-[800px] max-h-auto">
      <div className="w-[90%] mx-auto">
        <div className="py-8 px-4">
          <div className="col-lg-6 col-md-7 ">
            <div className="titlepage md:w-[50%] w-[100%]">
              <h2 className="text-4xl font-bold mb-10 mt-7">Why Choose Us</h2>
              <p className="text-xl text-justify ">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomisedThere are many variations of
                passages of Lorem Ipsum available, but the majority have
                suffered alteration in some form, by injected humour, or
                randomisedThere are many variations of passages of Lorem Ipsum
                available, but the majority have suffered alteration in some
                form, by injected humour, or randomised
              </p>
              <div className="award mt-32 flex justify-between gap-5">
                <div
                  id="awa_ho"
                  className="w-[200px] h-[150px] flex flex-col justify-center items-center text-center rounded-lg award_icon bg-white text_align_center  hover:bg-oranges"
                >
                  <i>
                    <img src={chImg} alt="#" className="mx-auto mb-2" />
                  </i>
                  <strong>client satisfaction</strong>
                </div>
                <div
                  id="awa_ho"
                  className="w-[200px] h-[150px] flex flex-col justify-center items-center text-center rounded-lg award_icon bg-white text_align_center  hover:bg-yellow"
                >
                  <i>
                    <img src={chImg1} alt="#" className="mx-auto" />
                  </i>
                  <strong>award</strong>
                </div>
                <div
                  id="awa_ho"
                  className="w-[200px] h-[150px] flex flex-col justify-center items-center text-center rounded-lg award_icon bg-white text_align_center hover:bg-yellow"
                >
                  <i>
                    <img src={chImg2} alt="#" />
                  </i>
                  <strong>Trustable</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choice;
