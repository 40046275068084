import React from "react";
import ClientImg from "../images/t.png";
import ClientImg1 from "../images/tes.png";

const Clinet = () => {
  return (
    <>
      <div className=" bg-darkcolor max-h-auto text-white ">
        <div className="w-[90%] mx-auto py-12 px-2">
          <div className="">
            <h2 className="text-4xl font-bold text-center">Clients Words</h2>
            <p className="text-md text-center mt-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor{" "}
            </p>
          </div>
          <div className="bg-white rounded-md  text-black md:w-[50%] w-[100%] max-h-fit mx-auto mt-16">
            <div className=" inherit text-left border-t-[110px] border-oranges rounde-md mx-3">
              <div className="flex">
                <i className="mt-[-54px] relative block">
                  <img
                    className="qusright absolute  md:visible invisible bottom-[280px] left-10"
                    src={ClientImg}
                    alt="#"
                  />
                  <img src={ClientImg1} alt="#" className="ml-20 mt-8" />
                  <img
                    className="qusright absolute  md:visible invisible bottom-[280px] left-52"
                    src={ClientImg}
                    alt="#"
                  />
                </i>
                <div className="consect md:px-8 px-0 mt-12 py-10">
                  <p className="text-3xl font-bold ">consectetur</p>
                  <p className="mt-3 text-md text-justify">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniamLorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clinet;
