import React from "react";
import footerImg from "../images/Group 3844.png";

import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter, AiFillYoutube } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="bg-coco p-12">
        <div className="w-[90%] mx-auto text-white items-center">
          <div className="flex md:flex-nowrap flex-wrap justify-between items-center text-white">
            <div className="text-center md:w-[33%] w-[100%]">
              <div className="">
                <h1 className="text-4xl font-bold mb-4">About</h1>
                <p className="font-heading">
                  CERBOSYS is a software development company that provides
                  customized and creative services for online businesses. The
                  services we provide have been instrumental in helping shape
                  several start-up companies by meeting their changing needs.
                </p>
              </div>
            </div>
            <div className=" md:w-[33%] w-[100%]">
              <div className="text-center">
                <h1 className="text-4xl font-bold mb-4">Address</h1>
                <p className="font-heading">
                  Address: 15-16 Lakshya Vihar Indore
                </p>
                <p className="font-heading">Tel: 0731 499 3444</p>
                <p className="font-heading">website: www.cerbosys.com</p>
              </div>
            </div>
            <div className="text-center md:w-[33%] w-[100%]">
              <div>
                <img src={footerImg} alt="" className="mx-auto" />
              </div>
              <div className=" flex mt-5 gap-8 items-center text-center justify-center">
                <div className="">
                  <FaFacebookF />
                </div>
                <div className="">
                  <AiOutlineTwitter />
                </div>
                <div className="">
                  <FaLinkedinIn />
                </div>
                <div className="">
                  <AiFillYoutube />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-sky text-center text-white p-5">
        <p>© 2019 All Rights Reserved. Design by Cerbosys</p>
      </div>
    </>
  );
};

export default Footer;
