import React from "react";
import testImg from "../images/ul.png";

const Customer = () => {
  return (
    <>
      <div className="bg-backg py-10 px-5 my-10">
        <div className="mt-16 w-[90%] mx-auto">
          <div className="flex md:flex-nowrap flex-wrap gap-20 justify-center ">
            <div className="md:w-[50%] w-[100%] ">
              <div className="p-5">
                <div className="">
                  <h4 className="text-xl font-bold text-orange ">
                    happy customer
                  </h4>
                  <h2 className="text-7xl mt-4 font-bold text-coco ">
                    SAY ABOUT US.
                  </h2>
                </div>

                <div className="mt-9">
                  <div
                    className="w-[100px] h-[6px] bg-orange"
                    id="testimonial-1"
                    role="tabpanel"
                  ></div>
                  <div className="mt-10">
                    <p className="text-xl text-gery font-heading text-justify leading-relaxed">
                      " It is a long established fact that a reader will
                      distracted by the reasdable and content page looking at it
                      and ayout the point using is that normal distribution of
                      letters."
                    </p>

                    <h2 className="text-coco text-3xl font-bold mt-12 mb-5">
                      Tonya Anderson
                    </h2>
                    <span className="text-orange text-md font-bold">
                      CEO OF INDUSTE, USA
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-[50%] w-[100%]">
              <div className="qu-icon">
                <img src={testImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
