import React from "react";
import image_1 from "../images/image_1.jpg";
import image_4 from "../images/image_4.jpg";
import image_2 from "../images/image_2.jpg";
import pic4 from "../images/pic4.jpg";
import pic5 from "../images/pic5.jpg";
import lastimg from "../images/t (2).jpg"
import { IoIosArrowForward } from "react-icons/io";

const Quality = () => {
  return (
    <>
      <div className="mt-12 w-[90%] mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold ">Quality With Dedication</h2>
          <p className="mt-3">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry has been<br></br> the industry's standard dummy text ever
            since the been when an unknown printer.
          </p>
        </div>
        <div className="flex md:flex-nowrap gap-10  flex-wrap justify-center items-center mt-8">
          <div className="md:w-[33%] w-[100%]">
            <div className="relative">
              <div>
                <img src={image_1} alt="img" />
              </div>
              <div className="absolute w-[90%]  bottom-[-10px] left-[10px] right-[10px] ">
                <div className="relative bg-redd p-3  rounded-md ">
                  <h2
                    className="text-white font-heading text-xl underline
              "
                  >
                    Mining Engineering
                  </h2>
                  <div className="absolute right-[10px] top-[5px] bottom-[4px] h-[80%] flex justify-center items-center w-[40px] bg-white">
                    <IoIosArrowForward className="text-2xl font-bold" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[33%] w-[100%]">
            <div className="relative">
              <div>
                <img src={image_4} alt="img" />
              </div>
              <div className="absolute w-[90%]  bottom-[-10px] left-[10px] right-[10px] ">
                <div className="relative bg-redd p-3  rounded-md ">
                  <h2
                    className="text-white font-heading text-xl underline
              "
                  >
                    Exploration Management
                  </h2>
                  <div className="absolute right-[10px] top-[5px] bottom-[4px] h-[80%] flex justify-center items-center w-[40px] bg-white">
                    <IoIosArrowForward className="text-2xl font-bold" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[33%] w-[100%]">
            <div className="relative">
              <div>
                <img src={image_2} alt="img" />
              </div>
              <div className="absolute w-[90%]  bottom-[-10px] left-[10px] right-[10px] ">
                <div className="relative bg-redd p-3  rounded-md ">
                  <h2
                    className="text-white font-heading text-xl underline
              "
                  >
                    Engineering Services
                  </h2>
                  <div className="absolute right-[10px] top-[5px] bottom-[4px] h-[80%] flex justify-center items-center w-[40px] bg-white">
                    <IoIosArrowForward className="text-2xl font-bold" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex md:flex-nowrap gap-10 flex-wrap justify-center items-center mt-8">
          <div className="md:w-[33%] w-[100%]">
            <div className="relative">
              <div>
                <img src={pic4} alt="img" />
              </div>
              <div className="absolute w-[90%]  bottom-[-10px] left-[10px] right-[10px] ">
                <div className="relative bg-redd p-3  rounded-md ">
                  <h2
                    className="text-white font-heading text-xl underline
              "
                  >
                    Mining Engineering
                  </h2>
                  <div className="absolute right-[10px] top-[5px] bottom-[4px] h-[80%] flex justify-center items-center w-[40px] bg-white">
                    <IoIosArrowForward className="text-2xl font-bold" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[33%] w-[100%]">
            <div className="relative">
              <div>
                <img src={pic5} alt="img" />
              </div>
              <div className="absolute w-[90%]  bottom-[-10px] left-[10px] right-[10px] ">
                <div className="relative bg-redd p-3  rounded-md ">
                  <h2
                    className="text-white font-heading text-xl underline
              "
                  >
                    Exploration Management
                  </h2>
                  <div className="absolute right-[10px] top-[5px] bottom-[4px] h-[80%] flex justify-center items-center w-[40px] bg-white">
                    <IoIosArrowForward className="text-2xl font-bold" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[33%] w-[100%]">
            <div className="relative">
              <div>
                <img src={lastimg} alt="img" />
              </div>
              <div className="absolute w-[90%]  bottom-[-10px] left-[10px] right-[10px] ">
                <div className="relative bg-redd p-3  rounded-md ">
                  <h2
                    className="text-white font-heading text-xl underline
              "
                  >
                    Engineering Services
                  </h2>
                  <div className="absolute right-[10px] top-[5px] bottom-[4px] h-[80%] flex justify-center items-center w-[40px] bg-white">
                    <IoIosArrowForward className="text-2xl font-bold" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quality;
